export default {
  accountValidateMsg:
    '账号必须为6-30位字符，包括字母、数字、“.”、“_”、“@”，第一位字符仅能为数字或字母。',
  storeSuccess: '储值成功',
                       accountName: '请输入帐号名称',
  password: '请输入密码',
  repeatPassword: '请再重复密码',
        privacyPolicy: '隐私策略',
                     login: '登入',
  logout: '登出',
         register: '注册',
      checkServiceTips: '请先阅读并接受服务条款和隐私政策！',
                     termsOfUse: '服务条款',
  copy_success: '复制成功',
          creatework_goGame: '返回游戏',
        mywork_confirm: '确认',
                 backLogin: '返回登录',
                       storeFail: '储值失败',
  storeFailTips:
    '非常抱歉本次储值失败了，请您尝试重新提交订单，或通过客服工单联系我们',
   storeSuccessTips: '储值成功后通常在15MIN内到账，请稍后于游戏内查看',
     failReason: '失败原因',
                forgetPwd: '忘记密码',
    goods: '商品',
      pwdValidateMsg: '密码必须为6-20位字符',
   fwtk: '服务条款',
  yszc: '隐私政策',
   remember: '记住我',
     favour: '收藏游戏',
  goStoreTip: '请跳转前往储值页面',
  go: '前往',
  otherLogin: '其它登录方式',
  qrCodeTip: '扫一扫二维码,马上开启旅程吧!',
  favourTip: '收藏页面到浏览器，方便下次再玩喔～',
  share: '分享给朋友',
  landscapeTip: '请用竖屏方式进行操作',
  leaveTip: '您真的要离开游戏吗？',
     lowerIE: '您的ie版本过低',
  linkTermText: '我已同意{mainHost}平台[服务条款]和[隐私政策]',
  download: '下载',
  favourAndDownloadTip: '收藏到浏览器或者下载到电脑&lt;br/&gt;方便下次再玩',
                                                                                      loginStateNone: '未登陆或者session已经失效',
                                                                                                                                      registerTimeError: '注册时间不符；',
    registerIPError: '账号注册IP地址不符；',
        h5_favour_has_gift: '收藏游戏至浏览器方便下次再来~您已获得过奖励哦~',
  h5_favour_get_gift: '收藏游戏至浏览器方便下次再来，奖励已发送至您的邮箱~',
  h5_get_gift_fault: '领取失败，请稍后再试或前往游戏内联系客服处理',
  h5_favour_please_select: '请先选择伺服器/角色',
  h5_favour_login_get: '请先进入再收藏，确保奖励发放至您的邮箱哦~',
  h5_favour_tip: '收藏游戏至浏览器方便下次再来～',
  h5_favour_select_serve: '请选择伺服器',
  h5_favour_select_role: '请选择角色',
     serviceTitle: '客服工单',
  h5_download_login: '请先进入游戏再下载，确保奖励发放至您的邮箱哦~',
  h5_download_tips: 'Please start the game from the client.',
  h5_download_repeat_get: '保存到桌面方便下次再来~您已获得过奖励哦~',
  h5_download_succ: '保存到桌面方便下次再来，奖励已发送至您的邮箱~',
  h5_download_repeat_role: '当前角色已被绑定',
   fb_canvas_fullscreen: '全屏模式',
  fb_canvas_website: '官网',
  fb_canvas_like_share: '点赞分享',
  fb_canvas_service: '客服',
  fb_canvas_login_fail: '登录失败',
  fb_canvas_pay_return: '您取消了本次交易',
  fb_canvas_root_tip: '请先进行Facebook的授权登录',
  h5_popup_title: '付款方式',
  h5_popup_submit: '付款',
  h5_popup_last_used: '上次使用',
  h5_popup_quit_tips: '是否确认取消付款？',
  h5_popup_quit_check: '是',
  h5_popup_quit_cancel: '否',
  h5_popup_currency_sign: '元',
  h5_popup_product_empty: '未获取到所选商品信息，请刷新页面',
  h5_popup_server_empty: '未获取到服务器列表，请刷新页面',
  aboutRelevanceAccount: '关于关联账户',
  selectLoginType: '请选择登录方式',
  touristDescription: '',
  touristSuggest: '',
  h5_tourist_remenber: '14天内记住我的密码',
                          h5_header_gift: '礼包',
  h5_award_popup_type: '奖励',
  h5_award_popup_content: '物品',
  h5_award_popup_code: '礼包兑换码',
  h5_favour_gift_name: '收藏礼包',
  h5_download_gift_name: '下载礼包',
   h5_succ_get_gift: '恭喜获得礼包兑换码',
  h5_exchange_info_title: '兑换说明',
   h5_exchange_info_2: '一个账号只能兑换一次下载礼包',
  h5_check_gift: '查詢獎勵',
         h5_guest_login: 'Guest',
  h5_click_copy: '点击复制',
   h5_header_store_text: '储值',
  h5_header_home_text: '主页',
  h5_check_gift_list_text: '查看奖励',
    h5_header_screen: '全屏幕',
  h5_verticalTip: '请用横屏方式进行操作',
  h5_third_party_login_tips: '',
                                    onestore_subscribe_goon_sub: '${year}年${month}月${day}日 续期',
                                                                                                                                                               googleLogin: 'Google登录',
                          loginFail: '登录失败，验证码错误',
                                                                                                                                                                     shareEventTitle: '邀好友 享好礼',
  shareRule: '活动规则',
         shareCode: '分享邀请码',
                       got: '已达成',
                                                               goToPay: '前往储值',
                          shareGift: '分享有礼',
                               pwsInstall: '添加至桌面',
                                         voucherAddHome: '添加至桌面',
  voucherAddHomeTipOne: '更快的支付，更好的体验',
  voucherAddHomeTipTwo: '点击工具栏的【分享】',
  voucherAddHomeTipThree: '并选择【添加至桌面】',
                                                                                                       goToSetting: '前往设置',
           loginSucc: '登录成功',
         registerNewAccount: '注册新账号',
                                                                                                         '-': '-',
  switch_horizontal: '',
  pwsInstall: '添加至桌面',
  voucherAddHome: '添加至桌面',
  voucherAddHomeTipOne: '更快的支付，更好的体验',
  voucherAddHomeTipTwo: '点击工具栏的【分享】',
  voucherAddHomeTipThree: '并选择【添加至桌面】'
}
