export default {
  accountValidateMsg:
    'Mật mã phải có từ 6-30 ký tự, bao gồm chữ cái, chữ số, “.”、“_”、“@”，ký tự đầu tiên phải là chữ số hoặc chữ cái.',
  storeSuccess: 'Nạp thành công',
                       accountName: 'Hãy nhập tên tài khoản',
  password: 'Mật Mã',
  repeatPassword: 'Hãy lặp lại mật mã',
        privacyPolicy: 'Chính sách bảo mật',
                     login: 'Đăng nhập',
  logout: 'Đăng xuất',
       register: 'Đăng ký',
      checkServiceTips:
    'Hãy đọc và đồng ý điều khoản bảo mật và chính sách phục vụ!',
                     termsOfUse: 'Điều khoản phục vụ',
  copy_success: 'Copy thành công',
          creatework_goGame: 'Quay về game',
        mywork_confirm: 'Xác nhận',
                backLogin: 'Quay về đăng nhập',
                       storeFail: 'Nạp thất bại',
  storeFailTips:
    'Lần Nạp này đã thất bại, hãy thử giao lại đơn của bạn hoặc liên hệ CSKH thông qua đơn đặt',
   storeSuccessTips:
    'Sau khi nạp thành công thì thông thường sẽ nhận được trong 15 phút, hãy đợi chút rồi kiểm tra',
     failReason: 'Nguyên nhân thất bại',
                forgetPwd: 'Quên mật khẩu',
    goods: 'Thương phẩm',
      pwdValidateMsg: 'Mật khẩu phải từ 6-20 ký tự',
   fwtk: 'Điều khoản phục vụ',
  yszc: 'Chính sách riêng tư',
   remember: 'Ghi nhớ',
     favour: 'Cất giữ game',
  goStoreTip: 'Vui lòng chuyển đến trang nạp tiền',
  go: 'Chuyển',
  otherLogin: 'Cách thức đăng nhập khác',
  qrCodeTip: 'Quét mã QR để chơi trên điện thoại',
  favourTip: 'Cất giữ trang nay vào trình duyệt để thuận tiện cho lần sau chơi',
  share: 'Chia sẻ với bạn bè',
  landscapeTip: 'Vui lòng thao tác ở chế độ dọc',
  leaveTip: 'Bạn thật sự muốn thoắt game?',
     lowerIE:
    'Phiên bản trình duyệt IE của bạn quá thấp, hãy truy cấp lại sau khi cập nhật',
  linkTermText:
    'Tôi đồng ý với [Điều khoản dịch vụ] và [Chính sách bảo mật] của {mainHost}',
  download: 'Tải',
  favourAndDownloadTip:
    'Cất giữ trang này vào trình duyệt hoặc tải về máy tính&lt;br/&gt;Lần sau chơi thuận tiện hơn',
                                                                                      loginStateNone: 'Chưa đăng nhập hoặc session đã thất bại',
                                                                                                                                      registerTimeError: 'Thời gian tạo không trùng khớp;',
    registerIPError: 'IP tạo tài khoản không trùng khớp;',
       h5_favour_has_gift:
    'Sưu tập game vào trình duyệt để tiện đăng nhập lần sau~Bạn đã nhận thưởng rồi~',
  h5_favour_get_gift:
    'Sưu tập game vào trình duyệt để tiện đăng nhập lần sau, thưởng đã được gửi vào hòm thư của bạn~',
  h5_get_gift_fault:
    'Lĩnh thưởng thất bại, vui lòng thử lại sau hoặc liên hệ CSKH trong game để xử lý',
  h5_favour_please_select: 'Hãy chọn Máy chủ/Nhân vật trước',
  h5_favour_login_get:
    'Hãy vào game trước rồi sưu tập, để đảm bảo thưởng được gửi tới hòm thư của bạn~',
  h5_favour_tip: 'Sưu tập game vào trình duyệt để tiện đăng nhập lần sau～',
  h5_favour_select_serve: 'Please choose server',
  h5_favour_select_role: 'Please choose character',
     serviceTitle: 'Hỗ Trợ',
  h5_download_login: 'Vui lòng đăng nhập tài khoản trước!',
  h5_download_tips: 'Vui lòng mở game qua ứng dụng!',
  h5_download_repeat_get: 'Bạn đã nhận được gói quà này rồi!',
  h5_download_succ: 'Vui lòng tải ứng dụng trước!',
  h5_download_repeat_role: 'Nhân vật này đã bị liên kết',
   fb_canvas_fullscreen: 'Toàn mành hình',
  fb_canvas_website: 'Trang chủ',
  fb_canvas_like_share: 'Chia sẻ',
  fb_canvas_service: 'CS',
  fb_canvas_login_fail: 'Đăng nhập thất bại',
  fb_canvas_pay_return: 'Bạn đã hủy bỏ giao dịch lần này',
  fb_canvas_root_tip: 'Hãy dùng tài khoản Facebook đăng nhập trước',
  h5_popup_title: 'Payment way',
  h5_popup_submit: 'Pay',
  h5_popup_last_used: 'Last used',
  h5_popup_quit_tips: 'Are you sure to cancel payment',
  h5_popup_quit_check: 'YES',
  h5_popup_quit_cancel: 'NO',
  h5_popup_currency_sign: '$',
  h5_popup_product_empty:
    'Product information has not been obtained yet. Please refresh page',
  h5_popup_server_empty: 'There is no server list yet. Please refresh page',
  aboutRelevanceAccount: '',
  selectLoginType: '',
  touristDescription: '',
  touristSuggest: '',
  h5_tourist_remenber: '',
                          h5_header_gift: '',
  h5_award_popup_type: '',
  h5_award_popup_content: '',
  h5_award_popup_code: '',
  h5_favour_gift_name: '',
  h5_download_gift_name: '',
   h5_succ_get_gift: '',
  h5_exchange_info_title: '',
   h5_exchange_info_2: '',
  h5_check_gift: '',
         h5_guest_login: 'Guest',
  h5_click_copy: 'Nhấp để copy',
   h5_header_store_text: 'Nạp Thẻ',
  h5_header_home_text: 'Trang Chủ',
  h5_check_gift_list_text: 'Chi tiết thưởng',
    h5_header_screen: 'Toàn màn hình',
  h5_verticalTip: 'Vui lòng xoay màn hình để thao tác',
  h5_third_party_login_tips: '',
  '-': '-',
  switch_horizontal: '',
  pwsInstall: 'Thêm vào giao diện',
  voucherAddHome: 'Thêm vào giao diện',
  voucherAddHomeTipOne: 'Nạp nhanh hơn, trải nghiệm tốt hơn',
  voucherAddHomeTipTwo: 'Nhấp [Chia Sẻ] trong công cụ',
  voucherAddHomeTipThree: 'và chọn [Thêm vào giao diện]'
}
