export default {
  accountValidateMsg:
    '帳號必須為6-30位元字元，包括字母、數位、“.”、“_”、“@”，第一位字元僅能為數位或字母。',
  storeSuccess: '儲值成功',
                       accountName: '請輸入帳號名稱',
  password: '請輸入密碼',
  repeatPassword: '請再重複密碼',
        privacyPolicy: '隱私權原則',
                     login: '登入',
  logout: '登出',
       register: '註冊',
      checkServiceTips: '請先閱讀並接受服務條款和隱私政策！',
                     termsOfUse: '服務條款',
  copy_success: '複製成功',
          creatework_goGame: '返回遊戲',
        mywork_confirm: '確認',
                backLogin: '返回登錄',
                       storeFail: '儲值失敗',
  storeFailTips:
    '非常抱歉本次儲值失敗了，請您嘗試重新提交訂單，或通過客服工單聯繫我們',
   storeSuccessTips: '儲值成功後通常在15MIN內到賬，請稍後於遊戲內查看',
     failReason: '失敗原因',
                forgetPwd: '忘記密碼',
    goods: '商品',
      pwdValidateMsg: '密碼必須為6-20位元字元',
   fwtk: '服務條款',
  yszc: '隱私政策',
   remember: '記住我',
     favour: '收藏遊戲',
  goStoreTip: '請跳轉前往儲值頁面',
  go: '前往',
  otherLogin: '其他登錄方式',
  qrCodeTip: '掃一掃二維碼，馬上開啟旅程吧！',
  favourTip: '收藏頁面到瀏覽器，方便下次再玩喔～',
  share: '分享給朋友',
  landscapeTip: '請用豎屏方式進行操作',
  leaveTip: '您真的要離開遊戲嗎？',
     lowerIE: '您的IE版本過低',
  linkTermText: '我已同意{mainHost}平台[服務條款]和[隱私政策]',
  download: '下載',
  favourAndDownloadTip: '收藏到瀏覽器或者下載到電腦&lt;br/&gt;方便下次再玩',
                                                                                      loginStateNone: '未登入或者session已經失效',
                                                                                                                                      registerTimeError: '註冊時間不符；',
    registerIPError: '帳號註冊IP地址不符；',
       h5_favour_has_gift: '收藏遊戲至瀏覽器方便下次再來～您已獲得過獎勵哦～',
  h5_favour_get_gift: '收藏遊戲至瀏覽器方便下次再來，獎勵已發送至您的郵箱～',
  h5_get_gift_fault: '領取失敗，請稍後再試或前往遊戲內聯繫客服處理',
  h5_favour_please_select: '請先選擇伺服器/角色',
  h5_favour_login_get: '請先進入遊戲再收藏，確保獎勵發放至您的郵箱哦～',
  h5_favour_tip: '收藏遊戲至瀏覽器方便下次再來～',
  h5_favour_select_serve: '請選擇伺服器',
  h5_favour_select_role: '請選擇角色',
     serviceTitle: '客服工单',
  h5_download_login: '請先進入遊戲再下載，確保獎勵發放至您的郵箱哦～',
  h5_download_tips: 'Please start the game from the client.',
  h5_download_repeat_get: '保存到桌面方便下次再來～您已獲得過獎勵哦～',
  h5_download_succ: '保存到桌面方便下次再來，獎勵已發送至您的郵箱～',
  h5_download_repeat_role: '當前角色已被綁定',
   fb_canvas_fullscreen: '全屏模式',
  fb_canvas_website: '官網',
  fb_canvas_like_share: '點讚分享',
  fb_canvas_service: '客服',
  fb_canvas_login_fail: '登錄失敗',
  fb_canvas_pay_return: '您取消了本次交易',
  fb_canvas_root_tip: '請先進行Facebook的授權登錄',
  h5_popup_title: '付款方式',
  h5_popup_submit: '付款',
  h5_popup_last_used: '上次使用',
  h5_popup_quit_tips: '是否取消付款？',
  h5_popup_quit_check: '是',
  h5_popup_quit_cancel: '否',
  h5_popup_currency_sign: '元',
  h5_popup_product_empty: '未獲取到所選商品信息，請刷新頁面',
  h5_popup_server_empty: '未獲取到服務器列表，請刷新頁面',
  aboutRelevanceAccount: '關於關聯帳戶',
  selectLoginType: '請選擇登錄方式',
  touristDescription: '',
  touristSuggest: '',
  h5_tourist_remenber: '14天內記住我的密碼',
                          h5_header_gift: '禮包',
  h5_award_popup_type: '獎勵',
  h5_award_popup_content: '物品',
  h5_award_popup_code: '禮包兌換碼',
  h5_favour_gift_name: '收藏禮包',
  h5_download_gift_name: '下載禮包',
   h5_succ_get_gift: '恭喜獲得禮包兌換碼',
  h5_exchange_info_title: '兌換說明',
   h5_exchange_info_2: '一個帳號只能兌換一次下載禮包',
  h5_check_gift: '查詢獎勵',
         h5_guest_login: 'Guest',
  h5_click_copy: '',
   h5_header_store_text: '儲值',
  h5_header_home_text: '主頁',
  h5_check_gift_list_text: '查看獎勵',
    h5_header_screen: '全螢幕',
  h5_verticalTip: '請用橫屏方式進行操作',
  h5_third_party_login_tips: '',
  '-': '-',
  switch_horizontal: '',
  pwsInstall: '添加至桌面',
  voucherAddHome: '添加至桌面',
  voucherAddHomeTipOne: '更快的支付，更好的體驗',
  voucherAddHomeTipTwo: '點擊工具列的【分享】',
  voucherAddHomeTipThree: '並選擇【添加至桌面】'
}
