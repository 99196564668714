export default {
  accountValidateMsg:
    'La cuenta debe contener de 6 a 30 caracteres, incluidas letras, dígitos y símbolos (".", "_", "@"). El primer carácter debe ser dígito o letra.',
  storeSuccess: 'Éxito de Recarga',
                       accountName: 'Nombre de la cuenta',
  password: 'Por favor, ingresa tu contraseña',
  repeatPassword: 'Por favor repite tu contraseña',
        privacyPolicy: 'Política de Privacidad',
                     login: 'Iniciar Sesión',
  logout: 'Salir',
       register: 'Registro',
      checkServiceTips:
    'Lea primero los Términos de Uso y la Política de Privacidad.',
                     termsOfUse: 'Términos de Uso',
  copy_success: 'Copiado',
          creatework_goGame: 'Volver',
        mywork_confirm: 'CONFIRMAR',
                backLogin: 'DISMINUCIÓN',
                       storeFail: 'Failed to recharge',
  storeFailTips:
    'Transacción fallida. Por favor, envíe el pedido de nuevo o póngase en contacto con nuestro servicio al cliente.',
   storeSuccessTips: 'Transaction shall be done in 15 mins.',
     failReason: 'Razón',
                forgetPwd: '¿Olvidaste tu contraseña?',
    goods: 'Objeto',
      pwdValidateMsg: '6 a 20 caracteres',
   fwtk: 'TÉRMINOS DE SERVICIO',
  yszc: 'POLÍTICA DE PRIVACIDAD',
   remember: 'Recuerda mi elección',
     favour: 'Marcador',
  goStoreTip: 'Por favor, vaya a la página de pago.',
  go: 'Ir',
  otherLogin: 'Otros métodos de inicio de sesión',
  qrCodeTip: 'Escanee el código QR para empezar!',
  favourTip: 'Marca la página para jugar de nuevo más tarde!',
  share: 'Compartir',
  landscapeTip: 'Por favor proceda en modo retrato',
  leaveTip: '¿Salir del juego?',
     lowerIE: 'IE version too old',
  linkTermText:
    'I have accepted {mainHost} [Terms of Use] and [Privacy Policy]',
  download: 'Download',
  favourAndDownloadTip: 'Bookmark this page to browser or download on PC',
                                                                                      loginStateNone: 'Not logged in or session has expired.',
                                                                                                                                      registerTimeError: 'Inconsistent Registration Date;',
    registerIPError: 'Inconsistent Registration IP address;',
       h5_favour_has_gift:
    'Bookmark to browser! (This account has claimed the reward already)',
  h5_favour_get_gift:
    'Congrats! You obtained the reward. Please check in-game.',
  h5_get_gift_fault:
    'Unable to claim. Please try again later, and contact our CS if the problem remains.',
  h5_favour_please_select: 'Please choose server/character',
  h5_favour_login_get: 'Log in and bookmark to the browser to claim a reward!',
  h5_favour_tip: 'Bookmark to the browser to claim a reward!',
  h5_favour_select_serve: 'Please choose server',
  h5_favour_select_role: 'Please choose character',
     serviceTitle: 'Service',
  h5_download_login: 'Please log in first.',
  h5_download_tips: 'Please start the game from the client.',
  h5_download_repeat_get: 'You have claimed this giftpack.',
  h5_download_succ: 'Please download the client first.',
  h5_download_repeat_role: 'Character is bound',
   fb_canvas_fullscreen: 'Expand',
  fb_canvas_website: 'Homepage',
  fb_canvas_like_share: 'Share',
  fb_canvas_service: 'Support',
  fb_canvas_login_fail: 'Login failed',
  fb_canvas_pay_return: 'You have cancelled the transaction',
  fb_canvas_root_tip: 'Please log in Facebook to authorize first',
  h5_popup_title: 'Payment way',
  h5_popup_submit: 'Pay',
  h5_popup_last_used: 'Last used',
  h5_popup_quit_tips: 'Are you sure to cancel payment',
  h5_popup_quit_check: 'YES',
  h5_popup_quit_cancel: 'NO',
  h5_popup_currency_sign: '$',
  h5_popup_product_empty:
    'Product information has not been obtained yet. Please refresh page',
  h5_popup_server_empty: 'There is no server list yet. Please refresh page',
  aboutRelevanceAccount: '',
  selectLoginType: '',
  touristDescription: '',
  touristSuggest: '',
  h5_tourist_remenber: '',
                          h5_header_gift: '',
  h5_award_popup_type: '',
  h5_award_popup_content: '',
  h5_award_popup_code: '',
  h5_favour_gift_name: '',
  h5_download_gift_name: '',
   h5_succ_get_gift: '',
  h5_exchange_info_title: '',
   h5_exchange_info_2: '',
  h5_check_gift: '',
         h5_guest_login: 'Guest',
  h5_click_copy: '',
   h5_header_store_text: 'Recharge',
  h5_header_home_text: 'Homepage',
  h5_check_gift_list_text: 'Info de Premios',
    h5_header_screen: 'Pantalla Completa',
  h5_verticalTip: 'Se recomienda el modo apaisado.',
  h5_third_party_login_tips: '',
  '-': '-',
  switch_horizontal: '',
  pwsInstall: 'Añadir a la pantalla de inicio',
  voucherAddHome: 'Añadir al escritorio',
  voucherAddHomeTipOne: 'Pagos más rápidos, mejor experiencia',
  voucherAddHomeTipTwo: 'Toca [Compartir] en la barra de herramientas',
  voucherAddHomeTipThree: 'Selecciona [Añadir al escritorio]'
}
