export default {
  accountValidateMsg:
    'アカウントの長さは6-30文字。英数字、記号（＠/_/.)を使用でき、イニシャルは英数字のみです。',
  storeSuccess: 'チャージ成功',
                       accountName: 'アカウント名を入力してください',
  password: 'パスワードを入力してください',
  repeatPassword: 'パスワードを再入力してください',
        privacyPolicy: 'プライバシーポリシー',
                     login: 'ログイン',
  logout: 'ログアウト',
       register: '登録',
      checkServiceTips: '利用規約とプライバシーポリシーを閲覧後承認してください！',
                     termsOfUse: '利用規約',
  copy_success: 'コピーしました',
          creatework_goGame: 'ゲームに戻る',
        mywork_confirm: '確認',
                backLogin: 'ログインに戻る',
                       storeFail: 'チャージを完了できませんでした',
  storeFailTips:
    'チャージ失敗。リトライしてください。ご不明点はカスタマーセンターにお問い合わせください',
   storeSuccessTips: 'お取引後、15分以内にチャージされますので、ご確認ください',
     failReason: '失敗原因',
                forgetPwd: 'パスワードを忘れた',
    goods: '商品',
      pwdValidateMsg: '半角6-20字以内',
   fwtk: '利用規約',
  yszc: 'プライバシーポリシー',
   remember: 'ログインしたままにする',
     favour: 'お気に入り',
  goStoreTip: 'チャージ画面へお進みください',
  go: '進む',
  otherLogin: 'その他の方法',
  qrCodeTip: 'QRコードをスキャンしてください',
  favourTip: 'お気に入りに保存すると、次回スムーズにスタートできます',
  share: 'フレンドにシェア',
  landscapeTip: '画面を縦にして操作してください',
  leaveTip: 'ゲームを終了しますか？',
     lowerIE:
    '申し訳ございません。IE11以下のブラウザには対応しておりません。ブラウザを更新していただくか、下記QRコードよりアプリをダウンロードしてお楽しみください。',
  linkTermText:
    '{mainHost}の[利用規約]<br/>及び[プライバシーポリシー]を同意します',
  download: 'ダウンロード',
  favourAndDownloadTip:
    '旦那様、ブックマーク登録又はダウンロードしてくださいまし(✿◡‿◡)&lt;br/&gt;次回もお会いしとうございます',
                                                                                      loginStateNone: 'Not logged in or session has expired.',
                                                                                                                                      registerTimeError: 'Inconsistent Registration Date;',
    registerIPError: 'Inconsistent Registration IP address;',
       h5_favour_has_gift:
    'お気に入りの報酬が届きました、メールでお受け取り下さい。',
  h5_favour_get_gift: 'お気に入りに追加すると、報酬がもらえますよ～',
  h5_get_gift_fault:
    '受領失敗、リトライするかカスタマー・サービスよりお問い合わせください。',
  h5_favour_please_select: 'サーバー/キャラを選択してください',
  h5_favour_login_get:
    '報酬が確実に届くように、まずログインしてキャラクターを作成してください～',
  h5_favour_tip: 'どうぞメールでお受け取り下さい～',
  h5_favour_select_serve: 'Please choose server',
  h5_favour_select_role: 'Please choose character',
     serviceTitle: 'カスタマーサービス',
  h5_download_login:
    '報酬が確実に届くように、まずログインしてキャラクターを作成してください～',
  h5_download_tips: 'Please start the game from the client.',
  h5_download_repeat_get:
    'ダウンロードの報酬が届きました、メールでお受け取り下さい。',
  h5_download_succ: 'ダウンロードをすると、報酬がもらえますよ～',
  h5_download_repeat_role: 'キャラがすでに連携されました',
   fb_canvas_fullscreen: 'fullｽｸﾘｰﾝ',
  fb_canvas_website: 'HP',
  fb_canvas_like_share: 'ｼｪｱ',
  fb_canvas_service: 'CS',
  fb_canvas_login_fail: 'ログイン失敗',
  fb_canvas_pay_return: 'キャンセルしました',
  fb_canvas_root_tip: 'Facebookへのログインを許可してください',
  h5_popup_title: 'お支払い',
  h5_popup_submit: 'お支払い',
  h5_popup_last_used: '前回使用',
  h5_popup_quit_tips: 'お支払をキャンセルしますか？',
  h5_popup_quit_check: 'YES',
  h5_popup_quit_cancel: 'NO',
  h5_popup_currency_sign: '円',
  h5_popup_product_empty: '製品情報はまだ取得されていません。更新してください',
  h5_popup_server_empty: 'サーバーリストはまだありません。更新してください',
  aboutRelevanceAccount: 'アカウントの連携について',
  selectLoginType: 'ログインする方法を選んでください',
  touristDescription:
    '◆ WEBとアプリとの外部サービス認証（Facebook、Twitter、Google Play）の種類が異なることがございます。',
  touristSuggest:
    '◆ 外部サービス認証でアプリと連携できない場合は、引き継ぎコードで連携をお行いください。',
  h5_tourist_remenber: '14日間この選択を記憶する',
                          h5_header_gift: 'ギフト',
  h5_award_popup_type: '報酬',
  h5_award_popup_content: 'アイテム',
  h5_award_popup_code: 'ギフト交換コード',
  h5_favour_gift_name: 'フォローギフト',
  h5_download_gift_name: 'ダウンロードギフト',
   h5_succ_get_gift: 'ギフト交換コードを手に入れました',
  h5_exchange_info_title: '交換方法',
   h5_exchange_info_2:
    '一つのアカウントは「ダウンロードギフト」を1回だけ交換できる',
  h5_check_gift: '報酬確認',
         h5_guest_login: 'ゲストログイン',
  h5_click_copy: '',
   h5_header_store_text: 'チャージ',
  h5_header_home_text: '公式サイト',
  h5_check_gift_list_text: '報酬一覧',
    h5_header_screen: '全画面表示',
  h5_verticalTip: 'ランドスケープモードでプレイしてください',
  h5_third_party_login_tips:
    'SNSアカウント(Facebook , Googleアカウントなど)でログインする場合、ゲストログインした後にユーザーセンターにてSNSアカウントと連携することができます。',
  '-': '-',
  switch_horizontal: '画面回転',
  pwsInstall: 'デスクトップに追加',
  voucherAddHome: 'デスクトップに追加',
  voucherAddHomeTipOne: '素早くて、スムーズな支払い体験',
  voucherAddHomeTipTwo: 'ツールバーの「シェア」ボタンをタップして、',
  voucherAddHomeTipThree: '「デスクトップに追加」を選択してください',
  voucherAddHome: '바탕화면에 추가',
  voucherAddHomeTipOne: '더 빠른 결제, 더 편한 서비스',
  voucherAddHomeTipTwo: '[공유] 버튼을 누르고',
  voucherAddHomeTipThree: '[바탕화면에 추가]를 선택합니다'
}
