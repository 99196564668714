export default {
  accountValidateMsg:
    '영문,숫자,“.”,“_”,“@” 포함. 6-30글자 제한. 첫 글자는 숫자나 영문이어야 합니다',
  storeSuccess: '충전 성공',
                       accountName: '계정을 입력하세요',
  password: '비밀번호 입력하세요',
  repeatPassword: '비밀번호를 다시 한 번 입력하세요',
        privacyPolicy: '개인정보보호정책',
                     login: '로그인',
  logout: '로그아웃',
       register: '회원 가입',
      checkServiceTips: '서비스 이용약관 및 개인정보보호정책을 읽고 동의하세요',
                     termsOfUse: '이용약관',
  copy_success: '복사 성공',
          creatework_goGame: '돌아가기',
        mywork_confirm: '확인',
                backLogin: '뒤로',
                       storeFail: '충전 실패',
  storeFailTips:
    '죄송합니다. 충전을 재시도 하시거나 고객센터로 연락해주시기 바랍니다',
   storeSuccessTips:
    '충전 성공 후, 15분내로 처리되오니 게임에서 확인하시기 바랍니다',
     failReason: '실패 원인',
                forgetPwd: '비밀번호 되찾기',
    goods: '아이템',
      pwdValidateMsg: '비밀번호는 6~20글자 이내여야합니다',
   fwtk: '서비스 약관',
  yszc: '개인정보 취급 방침',
   remember: '기억하기',
     favour: '찜하기',
  goStoreTip: '충전 페이지로 이동하세요',
  go: '가기',
  otherLogin: '다른 로그인 수단',
  qrCodeTip: 'QR코드를 스캔하고 시작하세요~',
  favourTip: '웹브라우저 북마크에 추가해놓으세요~',
  share: '공유하기',
  landscapeTip: '가로 모드를 이용해주세요',
  leaveTip: '정말로 게임을 나가시겠습니까?',
     lowerIE: 'IE 브라우저를 업그레이드하여 이용해주세요.',
  linkTermText:
    '{mainHost} 플랫품의 [서비스 이용약관] 및 [개인정보취급방침]에 동의합니다',
  download: '다운로드',
  favourAndDownloadTip: '브라우저에 저장하거나 컴퓨터에 설치해주세요.',
                                                                                      loginStateNone: '미접속 혹은 세션이 만료되었습니다',
                                                                                                                                      registerTimeError: '회원가입 시간 불일치；',
    registerIPError: '회원가입 IP주소 불일치；',
       h5_favour_has_gift:
    '게임을 즐겨찾기에 저장하세요~ 보상을 이미 수령하셨습니다~',
  h5_favour_get_gift:
    '게임을 즐겨찾기에 저장하세요~ 보상은 우편으로 발송되었습니다~',
  h5_get_gift_fault:
    '수령 실패. 잠시 후, 시도하시거나 게임 내 고객센터로 연락주시기 바랍니다.',
  h5_favour_please_select: '서버/캐릭터 선택하세요',
  h5_favour_login_get: '로그인 후, 즐겨찾기를 진행하셔야 보상이 지급됩니다~',
  h5_favour_tip: '게임을 즐겨찾기에 저장하세요~',
  h5_favour_select_serve: 'Please choose server',
  h5_favour_select_role: 'Please choose character',
     serviceTitle: '고객센터',
  h5_download_login: '먼저 로그인해주세요!',
  h5_download_tips: '클라이언트를 통해 로그인해주세요!',
  h5_download_repeat_get: '해당 게임 패키지를 이미 수령했습니다!',
  h5_download_succ: '클라이언트를 다운로드하세요!',
  h5_download_repeat_role: '이미 연동된 캐릭터입니다.',
   fb_canvas_fullscreen: '전체화면',
  fb_canvas_website: '홈페이지',
  fb_canvas_like_share: '공유',
  fb_canvas_service: '고객센터',
  fb_canvas_login_fail: '로그인 실패',
  fb_canvas_pay_return: '결제가 취소되었습니다.',
  fb_canvas_root_tip: '페이스북 로그인을 승인해 주세요.',
  h5_popup_title: '결제 수단',
  h5_popup_submit: '결제',
  h5_popup_last_used: '이전 결제 수단',
  h5_popup_quit_tips: '결제를 취소하시겠습니까?',
  h5_popup_quit_check: '예',
  h5_popup_quit_cancel: '아니오',
  h5_popup_currency_sign: '원',
  h5_popup_product_empty: '상품 정보 불러오기 실패. 화면을 갱신하세요.',
  h5_popup_server_empty: '서버 리스트 불러오기 실패. 화면을 갱신하세요.',
  aboutRelevanceAccount: '',
  selectLoginType: '',
  touristDescription: '',
  touristSuggest: '',
  h5_tourist_remenber: '',
                          h5_header_gift: '패키지',
  h5_award_popup_type: '보상',
  h5_award_popup_content: '아이템',
  h5_award_popup_code: '쿠폰 코드',
  h5_favour_gift_name: '즐겨찾기 패키지',
  h5_download_gift_name: '다운로드 패키지',
   h5_succ_get_gift: '쿠폰 코드를 획득하셨습니다.',
  h5_exchange_info_title: '교환 안내',
   h5_exchange_info_2: '계정당 다운로드 패키지 1번만 교환할 수 있습니다.',
  h5_check_gift: '보상 조회',
         h5_guest_login: 'Guest',
  h5_click_copy: '',
   h5_header_store_text: '충전',
  h5_header_home_text: '홈페이지',
  h5_check_gift_list_text: '보상 확인',
    h5_header_screen: '풀스크린',
  h5_verticalTip: '가로모드로 플레이하세요',
  h5_third_party_login_tips: '',
  '-': '-',
  switch_horizontal: '',
  pwsInstall: '바탕화면 바로가기 아이콘 생성',
  voucherAddHome: '바탕화면에 추가',
  voucherAddHomeTipOne: '더 빠른 결제, 더 편한 서비스',
  voucherAddHomeTipTwo: '[공유] 버튼을 누르고',
  voucherAddHomeTipThree: '[바탕화면에 추가]를 선택합니다'
}
