export default {
  accountValidateMsg:
    'ชื่อบัญชีต้องยาว 6-30 ตัวอักษร ประกอบด้วยพยัญชนะ ตัวเลข และสัญลักษณ์พิเศษ (“.”,“_”,“@”) ตัวอักษรแรกต้องเป็นตัวเลข หรือพยัญชนะเท่านั้น',
  storeSuccess: 'เติมเงินสำเร็จ',
                       accountName: 'โปรดป้อนชื่อบัญชี',
  password: 'โปรดป้อนรหัสผ่าน',
  repeatPassword: 'โปรดป้อนรหัสผ่านอีกครั้ง',
        privacyPolicy: 'นโยบายความเป็นส่วนตัว',
                     login: 'ล็อกอิน',
  logout: 'ล็อกเอาต์',
         register: 'สมัคร',
      checkServiceTips:
    'โปรดอ่านและยอมรับเงื่อนไขการให้บริการและนโยบายความเป็นส่วนตัว!',
                     termsOfUse: 'เงื่อนไขการให้บริการ',
  copy_success: 'คัดลอกสำเร็จ',
          creatework_goGame: 'กลับสู่เกม',
        mywork_confirm: 'ยืนยัน',
                 backLogin: 'ล็อกอิน',
                       storeFail: 'เติมเงินไม่สำเร็จ',
  storeFailTips:
    'ขออภัย ท่านเติมเงินไม่สำเร็จ โปรดสั่งซื้ออีกครั้ง หรือแจ้งปัญหาให้ฝ่ายบริการลูกค้า',
   storeSuccessTips: 'หลังเติมเงินสำเร็จ จะได้รับยอดเงินภายใน 15 นาที',
     failReason: 'สาเหตุล้มเหลว',
                forgetPwd: 'ลืมรหัสผ่าน',
    goods: 'สินค้า',
      pwdValidateMsg: 'รหัสผ่านต้องยาว 6-20 ตัวอักษร',
   fwtk: 'ข้อตกลงการให้บริการ',
  yszc: 'นโยบายความเป็นส่วนตัว',
   remember: 'จดจำฉัน',
     favour: 'บันทึกเกม',
  goStoreTip: 'โปรดเปิดหน้าเติมเงินก่อนไป',
  go: 'ไป',
  otherLogin: 'ล็อกอินด้วยช่องทางอื่น',
  qrCodeTip: 'สแกน และไปสนุกกันเลย !',
  favourTip: 'บันทึกหน้านี้ไว้ในบราวเซอร์ เพื่อความสะดวกในการเล่นครั้งหน้า',
  share: 'แชร์ให้เพื่อน',
  landscapeTip: 'โปรดทำรายการด้วยหน้าจอแนวตั้ง',
  leaveTip: 'ท่านแน่ใจไหมว่าจะออกจากเกม ?',
     lowerIE: 'เวอร์ชั่น IE ของท่านต่ำเกินไป',
  linkTermText:
    'ฉันยินยอมใน[ข้อตกลงการให้บริการ]และ[นโยบายความเป็นส่วนตัว]ของ {mainHost} แล้ว',
  download: 'ดาวน์โหลด',
  favourAndDownloadTip: 'บันทึกเพจนี้ไว้ในบุ๊คมาร์ค หรือดาวน์โหลด',
                                                                                      loginStateNone: 'ไม่ได้ล็อกอิน หรือ session หมดอายุ',
                                                                                                                                      registerTimeError: 'เวลาที่สมัครไม่สอดคล้อง;',
    registerIPError: 'IP ที่ใช้สมัครบัญชีไม่สอดคล้อง;',
        h5_favour_has_gift:
    'เพิ่มบุ๊กมาร์กในบราวเซอร์ เพื่อความรวดเร็วในการเล่นครั้งถัดไป~คุณรับรางวัลแล้ว',
  h5_favour_get_gift:
    'เพิ่มบุ๊กมาร์กในบราวเซอร์ เพื่อความรวดเร็วในการเล่นครั้งถัดไป~ส่งรางวัลเข้าอีเมลแล้ว',
  h5_get_gift_fault:
    'รับรางวัลไม่สำเร็จ โปรดลองใหม่ภายหลัง หรือติดตามฝ่ายบริการลูกค้าภายในเกม',
  h5_favour_please_select: 'โปรดเลือกเซิร์ฟ/ตัวละคร',
  h5_favour_login_get:
    'โปรดเพิ่มบุ๊กมาร์กหลังเข้าเกม เพื่อให้รางวัลถูกส่งเข้าอีเมลของคุณ',
  h5_favour_tip:
    'เพิ่มบุ๊กมาร์กในบราวเซอร์ เพื่อความรวดเร็วในการเล่นครั้งถัดไป',
  h5_favour_select_serve: 'Please choose server',
  h5_favour_select_role: 'Please choose character',
     serviceTitle: 'ฝ่ายบริการลูกค้า',
  h5_download_login: 'โปรดล็อกอินก่อน',
  h5_download_tips: 'โปรดเปิดเกมจากหน้าเพจที่บันทึกไว้บนเดสก์ท็อป !',
  h5_download_repeat_get: 'ท่านเคยรับรางวัลนี้แล้ว !',
  h5_download_succ: 'โปรดบันทึกหน้าเพจไว้บนเดสก์ท็อปก่อน !',
  h5_download_repeat_role: 'ตัวละครนี้ผูกมัดแล้ว',
   fb_canvas_fullscreen: 'โหมดเต็มหน้าจอ',
  fb_canvas_website: 'เว็บไซต์',
  fb_canvas_like_share: 'แชร์',
  fb_canvas_service: 'บริการลูกค้า',
  fb_canvas_login_fail: 'ล็อกอินล้มเหลว',
  fb_canvas_pay_return: 'คุณได้ยกเลิกการซื้อขายในครั้งนี้แล้ว',
  fb_canvas_root_tip: 'โปรดให้สิทธิ์การอนุุญาตล็อกอินผ่าน Facebook  ก่อน',
  h5_popup_title: 'Payment way',
  h5_popup_submit: 'Pay',
  h5_popup_last_used: 'Last used',
  h5_popup_quit_tips: 'Are you sure to cancel payment',
  h5_popup_quit_check: 'YES',
  h5_popup_quit_cancel: 'NO',
  h5_popup_currency_sign: '$',
  h5_popup_product_empty:
    'Product information has not been obtained yet. Please refresh page',
  h5_popup_server_empty: 'There is no server list yet. Please refresh page',
  aboutRelevanceAccount: '',
  selectLoginType: '',
  touristDescription: '',
  touristSuggest: '',
  h5_tourist_remenber: '',
                          h5_header_gift: '',
  h5_award_popup_type: '',
  h5_award_popup_content: '',
  h5_award_popup_code: '',
  h5_favour_gift_name: '',
  h5_download_gift_name: '',
   h5_succ_get_gift: '',
  h5_exchange_info_title: '',
   h5_exchange_info_2: '',
  h5_check_gift: '',
         h5_guest_login: 'Guest',
  h5_click_copy: '',
   h5_header_store_text: 'เติมเงิน',
  h5_header_home_text: 'เว็บไซต์',
  h5_check_gift_list_text: 'รางวัล',
    h5_header_screen: 'เต็มหน้าจอ',
  h5_verticalTip: 'โปรดเล่นด้วยแนวนอน',
  h5_third_party_login_tips: '',
                                    onestore_subscribe_goon_sub: 'Renew on ${month}/${day}/${year}',
                                                                                                                                                               googleLogin: 'Google ล็อกอิน',
                          loginFail: 'ล็อกอินไม่สำเร็จ เพราะรหัสยืนยันไม่ถูกต้อง',
                                                                                                                                                                     shareEventTitle: 'เชิญเพื่อน รับรางวัล',
  shareRule: 'กติกา',
         shareCode: 'แชร์โค้เชิญ',
      seeAwardTip:
    'สามารถเช็คและใช้ได้ใน "<span class=\'see-light\'>พอยท์ VIP</span>" - "<span class=\'see-light\'>คูปองของฉัน</span>"',
                  got: 'ครบแล้ว',
                                                               goToPay: 'ไปเติมเงิน',
                          shareGift: 'แชร์ทันที มีรางวัล',
                               pwsInstall: 'เพิ่มสู่เดสก์ท็อป',
                                         voucherAddHome: 'เพิ่มสู่เดสก์ท็อป',
  voucherAddHomeTipOne: 'ใช้ง่ายกว่า รวดเร็วกว่า',
  voucherAddHomeTipTwo: 'แตะ [แชร์] ในแถบเครื่องมือ',
  voucherAddHomeTipThree: 'และเลือก [เพิ่มสู่เดสก์ท็อป]',
                                                                                                       goToSetting: 'ไปตั้งค่า',
           loginSucc: 'ล็อกอินสำเร็จ',
         registerNewAccount: 'สมัครบัญชีใหม่',
                                                                                                         '-': '-',
  switch_horizontal: '',
  pwsInstall: 'เพิ่มสู่เดสก์ท็อป',
  voucherAddHome: 'เพิ่มสู่เดสก์ท็อป',
  voucherAddHomeTipOne: 'ใช้ง่ายกว่า รวดเร็วกว่า',
  voucherAddHomeTipTwo: 'แตะ [แชร์] ในแถบเครื่องมือ',
  voucherAddHomeTipThree: 'และเลือก [เพิ่มสู่เดสก์ท็อป]'
}
