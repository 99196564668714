export default {
  accountValidateMsg:
    'Account must contain 6 - 30 characters, including letters, digits and symbols (".", "_", "@"). The first character must be digit or letter.',
  storeSuccess: 'Recharge Success',
                       accountName: 'Account name',
  password: 'Please enter your password',
  repeatPassword: 'Please repeat your password',
        privacyPolicy: 'Privacy Policy',
                     login: 'Log in',
  logout: 'Log Out',
         register: 'SIGN UP',
      checkServiceTips: 'Please read Terms of Use and Privacy Policy first.',
                     termsOfUse: 'Terms of Use',
  copy_success: 'Copied',
          creatework_goGame: 'Return',
        mywork_confirm: 'CONFIRM',
                 backLogin: 'DECLINE',
                       storeFail: 'Failed to recharge',
  storeFailTips:
    'Transaction failed. Please submit order again or contact our customer service.',
   storeSuccessTips: 'Transaction shall be done in 15 mins.',
     failReason: 'Reason',
                forgetPwd: 'Forgot your password?',
    goods: 'Item',
      pwdValidateMsg: 'Password must contain 6 to 20 characters',
   fwtk: 'TERMS OF SERVICE',
  yszc: 'PRIVACY POLICY',
   remember: 'Remember my choice',
     favour: 'Bookmark',
  goStoreTip: 'Please proceed to the payment page',
  go: 'Go',
  otherLogin: 'Other login methods',
  qrCodeTip: 'Scan the QR code to get started!',
  favourTip: 'Bookmark the page to play again later!',
  share: 'Share',
  landscapeTip: 'Please proceed in portrait mode',
  leaveTip: 'Exit the game?',
     lowerIE: 'IE version too old',
  linkTermText:
    'I have accepted {mainHost} [Terms of Use] and [Privacy Policy]',
  download: 'Download',
  favourAndDownloadTip: 'Bookmark this page to browser or download on PC',
                                                                                      loginStateNone: 'Not logged in or session has expired.',
                                                                                                                                      registerTimeError: 'Inconsistent Registration Date;',
    registerIPError: 'Inconsistent Registration IP address;',
        h5_favour_has_gift:
    'Bookmark to browser! (This account has claimed the reward already)',
  h5_favour_get_gift:
    'Congrats! You obtained the reward. Please check in-game.',
  h5_get_gift_fault:
    'Unable to claim. Please try again later, and contact our CS if the problem remains.',
  h5_favour_please_select: 'Please choose server/character',
  h5_favour_login_get: 'Log in and bookmark to the browser to claim a reward!',
  h5_favour_tip: 'Bookmark to the browser to claim a reward!',
  h5_favour_select_serve: 'Please choose server',
  h5_favour_select_role: 'Please choose character',
     serviceTitle: 'Service',
  h5_download_login: 'Please log in first.',
  h5_download_tips: 'Please start the game from the client.',
  h5_download_repeat_get: 'You have claimed this giftpack.',
  h5_download_succ: 'Please download the client first.',
  h5_download_repeat_role: 'Character is bound',
   fb_canvas_fullscreen: 'Expand',
  fb_canvas_website: 'Homepage',
  fb_canvas_like_share: 'Share',
  fb_canvas_service: 'Support',
  fb_canvas_login_fail: 'Login failed',
  fb_canvas_pay_return: 'You have cancelled the transaction',
  fb_canvas_root_tip: 'Please log in Facebook to authorize first',
  h5_popup_title: 'Payment way',
  h5_popup_submit: 'Pay',
  h5_popup_last_used: 'Last used',
  h5_popup_quit_tips: 'Are you sure to cancel payment',
  h5_popup_quit_check: 'YES',
  h5_popup_quit_cancel: 'NO',
  h5_popup_currency_sign: '$',
  h5_popup_product_empty:
    'Product information has not been obtained yet. Please refresh page',
  h5_popup_server_empty: 'There is no server list yet. Please refresh page',
  aboutRelevanceAccount: 'Already have an account?',
  selectLoginType: 'Select a login method',
  touristDescription:
    'If you already have an account, you can log in as a guest and then use the OTP (One-Time Password) feature in the user center to switch accounts. This feature requires the OTP functionality to be set up on your mobile device in the user center.',
  touristSuggest:
    'If you already have an account, you can log in as a guest and then tap the "Switch Account" option in the user center. From there, you can log in using a previously bound social media account.',
  h5_tourist_remenber: 'Remember me for 14 days',
                          h5_header_gift: '',
  h5_award_popup_type: '',
  h5_award_popup_content: '',
  h5_award_popup_code: '',
  h5_favour_gift_name: '',
  h5_download_gift_name: '',
   h5_succ_get_gift: '',
  h5_exchange_info_title: '',
   h5_exchange_info_2: '',
  h5_check_gift: '',
         h5_guest_login: 'Guest',
  h5_click_copy: '',
   h5_header_store_text: 'Recharge',
  h5_header_home_text: 'Homepage',
  h5_check_gift_list_text: 'Reward Info',
    h5_header_screen: 'Full Screen',
  h5_verticalTip: 'Landscape mode is advised.',
  h5_third_party_login_tips:
    'If you want to log in using a social media account (such as Facebook, Google, Twitter, etc.), you can first log in as a guest and then bind your social media account in the user center.',
                                    onestore_subscribe_goon_sub: 'Renew on ${month}/${day}/${year}',
                                                                                                                                                               googleLogin: 'Log in with Google',
                          loginFail: 'Login failed. Incorrect password.',
                                                                                                                                                                     shareEventTitle: 'Invite Friends</br>Earn Rewards',
  shareRule: 'Rules',
         shareCode: 'Share Invite Code',
                       got: 'Achieved',
                                                               goToPay: 'Top Up',
                          shareGift: 'Share for Gifts',
                               pwsInstall: 'Add to Home Screen',
                                         voucherAddHome: 'Add to Desktop',
  voucherAddHomeTipOne: 'Faster Payment, Better Experience',
  voucherAddHomeTipTwo: 'Tap [Share] in the toolbar',
  voucherAddHomeTipThree: 'Select [Add to Desktop]',
                                                                                                       goToSetting: 'Settings',
           loginSucc: 'Logged in',
         registerNewAccount: 'Sign Up',
                                                                                                         '-': '-',
  switch_horizontal: '',
  pwsInstall: 'Add to Home Screen',
  voucherAddHome: 'Add to Desktop',
  voucherAddHomeTipOne: 'Faster Payment, Better Experience',
  voucherAddHomeTipTwo: 'Tap [Share] in the toolbar',
  voucherAddHomeTipThree: 'Select [Add to Desktop]'
}
