export default {
  accountValidateMsg:
    'Konto muss aus 6-30 Zeichen bestehen und enthält Buchstaben, Ziffern und Symbole (".", "_", "@"). Das erste Zeichen muss Ziffer oder Buchstabe sein.',
  storeSuccess: 'Erfolgreich aufladen',
                       accountName: 'Kontoname',
  password: 'Bitte Ihr Passwort eingeben',
  repeatPassword: 'Bitte wiederholen Sie Ihr Passwort',
        privacyPolicy: 'Datenschutzbestimmungen',
                     login: 'Einloggen',
  logout: 'Ausloggen',
       register: 'ANMELDEN',
      checkServiceTips:
    'Bitte zuerst Nutzungsbedingungen und Datenschutzbestimmungen lesen.',
                     termsOfUse: 'Nutzungsbedingungen',
  copy_success: 'Kopiert',
          creatework_goGame: 'Zurück',
        mywork_confirm: 'BESTÄTIGEN',
                backLogin: 'ABBRECHEN',
                       storeFail: 'Failed to recharge',
  storeFailTips:
    'Transaktion fehlgeschlagen. Bitte senden Sie die Bestellung erneut oder kontaktieren Sie unseren Kundenservice.',
   storeSuccessTips: 'Transaction shall be done in 15 mins.',
     failReason: 'Ursache',
                forgetPwd: 'Passwort vergessen?',
    goods: 'Gegenstand',
      pwdValidateMsg: '6 bis 20 Zeichen',
   fwtk: 'NUTZUNGSBEDINGUNGEN',
  yszc: 'DATENSCHUTZ-BESTIMMUNGEN',
   remember: 'An meine Wahl erinnern',
     favour: 'Lesezeichen',
  goStoreTip: 'Bitte geh zur Zahlungsseite weiter',
  go: 'Gehen',
  otherLogin: 'Andere Anmeldemethoden',
  qrCodeTip: 'Scanne den QR-Code und geh los!',
  favourTip:
    'Setze ein Lesezeichen auf die Seite, um sie später wieder zu spielen!',
  share: 'Teilen',
  landscapeTip: 'Bitte mit Portraitmodus fortsetzen',
  leaveTip: 'Das Spiel verlassen?',
     lowerIE: 'IE version too old',
  linkTermText:
    'I have accepted {mainHost} [Terms of Use] and [Privacy Policy]',
  download: 'Download',
  favourAndDownloadTip: 'Bookmark this page to browser or download on PC',
                                                                                      loginStateNone: 'Not logged in or session has expired.',
                                                                                                                                      registerTimeError: 'Inconsistent Registration Date;',
    registerIPError: 'Inconsistent Registration IP address;',
       h5_favour_has_gift:
    'Bookmark to browser! (This account has claimed the reward already)',
  h5_favour_get_gift:
    'Congrats! You obtained the reward. Please check in-game.',
  h5_get_gift_fault:
    'Unable to claim. Please try again later, and contact our CS if the problem remains.',
  h5_favour_please_select: 'Please choose server/character',
  h5_favour_login_get: 'Log in and bookmark to the browser to claim a reward!',
  h5_favour_tip: 'Bookmark to the browser to claim a reward!',
  h5_favour_select_serve: 'Please choose server',
  h5_favour_select_role: 'Please choose character',
     serviceTitle: 'Service',
  h5_download_login: 'Please log in first.',
  h5_download_tips: 'Please start the game from the client.',
  h5_download_repeat_get: 'You have claimed this giftpack.',
  h5_download_succ: 'Please download the client first.',
  h5_download_repeat_role: 'Character is bound',
   fb_canvas_fullscreen: 'Expand',
  fb_canvas_website: 'Homepage',
  fb_canvas_like_share: 'Share',
  fb_canvas_service: 'Support',
  fb_canvas_login_fail: 'Login failed',
  fb_canvas_pay_return: 'You have cancelled the transaction',
  fb_canvas_root_tip: 'Please log in Facebook to authorize first',
  h5_popup_title: 'Payment way',
  h5_popup_submit: 'Pay',
  h5_popup_last_used: 'Last used',
  h5_popup_quit_tips: 'Are you sure to cancel payment',
  h5_popup_quit_check: 'YES',
  h5_popup_quit_cancel: 'NO',
  h5_popup_currency_sign: '$',
  h5_popup_product_empty:
    'Product information has not been obtained yet. Please refresh page',
  h5_popup_server_empty: 'There is no server list yet. Please refresh page',
  aboutRelevanceAccount: '',
  selectLoginType: '',
  touristDescription: '',
  touristSuggest: '',
  h5_tourist_remenber: '',
                          h5_header_gift: '',
  h5_award_popup_type: '',
  h5_award_popup_content: '',
  h5_award_popup_code: '',
  h5_favour_gift_name: '',
  h5_download_gift_name: '',
   h5_succ_get_gift: '',
  h5_exchange_info_title: '',
   h5_exchange_info_2: '',
  h5_check_gift: '',
         h5_guest_login: 'Guest',
  h5_click_copy: '',
   h5_header_store_text: 'Recharge',
  h5_header_home_text: 'Homepage',
  h5_check_gift_list_text: 'Belohnungs-Info',
    h5_header_screen: 'Vollbild',
  h5_verticalTip: 'Querformatmodus wird empfohlen.',
  h5_third_party_login_tips: '',
  '-': '-',
  switch_horizontal: '',
  pwsInstall: 'Zum Startbildschirm hinzufügen',
  voucherAddHome: '添加至桌面',
  voucherAddHomeTipOne: '更快的支付，更好的體驗',
  voucherAddHomeTipTwo: '點擊工具列的【分享】',
  voucherAddHomeTipThree: '並選擇【添加至桌面】'
}
