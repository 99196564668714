export default {
  accountValidateMsg:
    'Account must contain 6 - 30 characters, including letters, digits and symbols (".", "_", "@"). The first character must be digit or letter.',
  storeSuccess: 'Пополнение прошло успешно',
                       accountName: 'Название аккаунта',
  password: 'Пожалуйста, введите свой пароль',
  repeatPassword: 'Подтвердите пароль',
        privacyPolicy: 'Политика конфиденциальности',
                     login: 'Войти',
  logout: 'выйти',
         register: 'зарегистрироваться',
      checkServiceTips: 'Please read Terms of Use and Privacy Policy first.',
                     termsOfUse: 'Условия использования',
  copy_success: 'Copied',
          creatework_goGame: 'Return',
        mywork_confirm: 'CONFIRM',
                 backLogin: 'DECLINE',
                       storeFail: 'Failed to recharge',
  storeFailTips:
    'Transaction failed. Please submit order again or contact our customer service.',
   storeSuccessTips: 'Транзакция будет выполнена в течение 15 минут.',
     failReason: 'Reason',
                forgetPwd: 'Забыли пароль?',
    goods: 'предмет',
      pwdValidateMsg: 'Password must contain 6 to 20 characters',
   fwtk: 'TERMS OF SERVICE',
  yszc: 'PRIVACY POLICY',
   remember: 'запомнить мой выбор',
     favour: 'Bookmark',
  goStoreTip: 'Please proceed to the payment page',
  go: 'Go',
  otherLogin: 'Other login methods',
  qrCodeTip: 'Scan the QR code to get started!',
  favourTip: 'Bookmark the page to play again later!',
  share: 'Share',
  landscapeTip: 'Please proceed in portrait mode',
  leaveTip: 'Exit the game?',
     lowerIE: 'IE version too old',
  linkTermText:
    'I have accepted {mainHost} [Terms of Use] and [Privacy Policy]',
  download: 'Download',
  favourAndDownloadTip: 'Bookmark this page to browser or download on PC',
                                                                                      loginStateNone: 'Not logged in or session has expired.',
                                                                                                                                      registerTimeError: 'Inconsistent Registration Date;',
    registerIPError: 'Inconsistent Registration IP address;',
        h5_favour_has_gift:
    'Bookmark to browser! (This account has claimed the reward already)',
  h5_favour_get_gift:
    'Congrats! You obtained the reward. Please check in-game.',
  h5_get_gift_fault:
    'Unable to claim. Please try again later, and contact our CS if the problem remains.',
  h5_favour_please_select: 'Please choose server/character',
  h5_favour_login_get: 'Log in and bookmark to the browser to claim a reward!',
  h5_favour_tip: 'Bookmark to the browser to claim a reward!',
  h5_favour_select_serve: 'Please choose server',
  h5_favour_select_role: 'Please choose character',
     serviceTitle: 'Service',
  h5_download_login: 'Please log in first.',
  h5_download_tips: 'Please start the game from the client.',
  h5_download_repeat_get: 'You have claimed this giftpack.',
  h5_download_succ: 'Please download the client first.',
  h5_download_repeat_role: 'Character is bound',
   fb_canvas_fullscreen: 'Expand',
  fb_canvas_website: 'Homepage',
  fb_canvas_like_share: 'Share',
  fb_canvas_service: 'Support',
  fb_canvas_login_fail: 'Login failed',
  fb_canvas_pay_return: 'You have cancelled the transaction',
  fb_canvas_root_tip: 'Please log in Facebook to authorize first',
  h5_popup_title: 'Payment way',
  h5_popup_submit: 'Pay',
  h5_popup_last_used: 'Last used',
  h5_popup_quit_tips: 'Are you sure to cancel payment',
  h5_popup_quit_check: 'YES',
  h5_popup_quit_cancel: 'NO',
  h5_popup_currency_sign: '$',
  h5_popup_product_empty:
    'Product information has not been obtained yet. Please refresh page',
  h5_popup_server_empty: 'There is no server list yet. Please refresh page',
  aboutRelevanceAccount: '',
  selectLoginType: '',
  touristDescription: '',
  touristSuggest: '',
  h5_tourist_remenber: '',
                          h5_header_gift: '',
  h5_award_popup_type: '',
  h5_award_popup_content: '',
  h5_award_popup_code: '',
  h5_favour_gift_name: '',
  h5_download_gift_name: '',
   h5_succ_get_gift: '',
  h5_exchange_info_title: '',
   h5_exchange_info_2: '',
  h5_check_gift: '',
         h5_guest_login: 'Guest',
  h5_click_copy: '',
   h5_header_store_text: 'Recharge',
  h5_header_home_text: 'Homepage',
  h5_check_gift_list_text: 'О Наградах',
    h5_header_screen: 'Полный Экран',
  h5_verticalTip: 'Рекомендуется альбомный режим.',
  h5_third_party_login_tips: '',
                                    onestore_subscribe_goon_sub: 'Renew on ${month}/${day}/${year}',
                                                                                                                                                               googleLogin: 'Войти с помощью Google',
                          loginFail: 'Не удалось войти. Неверный пароль.',
                                                                                                                                                                     shareEventTitle: 'Приглашайте Друзей</br>Получайте Награды',
  shareRule: 'Правила',
         shareCode: 'Поделитесь Кодом Приглашения',
                       got: 'Достигнуто',
                                                               goToPay: 'Пополнение',
                          shareGift: 'Делитесь и Получайте Подарки',
                               pwsInstall: 'Добавить на Главный экран',
                                         voucherAddHome: 'Добавить на рабочий стол',
  voucherAddHomeTipOne: 'Быстрые платежи, удобная оплата',
  voucherAddHomeTipTwo: 'Нажмите [Поделиться] на панели',
  voucherAddHomeTipThree: 'Выберите [Добавить на рабочий стол]',
                                                                                                       goToSetting: 'Настройки',
           loginSucc: 'Выполнен вход',
         registerNewAccount: 'Регистрация',
                                                                                                         '-': '-',
  switch_horizontal: '',
  pwsInstall: 'Добавить на Главный экран',
  voucherAddHome: 'Добавить на рабочий стол',
  voucherAddHomeTipOne: 'Быстрые платежи, удобная оплата',
  voucherAddHomeTipTwo: 'Нажмите [Поделиться] на панели',
  voucherAddHomeTipThree: 'Выберите [Добавить на рабочий стол]'
}
