export default {
  '-': 'Poprzedni',
  accountValidateMsg:
    'Konto musi zawierać od 6 do 30 znaków, w tym litery, cyfry i symbole (".", "_", "@"). Pierwszy znak musi być cyfrą lub literą.',
  storeSuccess: 'Udane doładowanie',
                       accountName: 'Nazwa konta',
  password: 'Proszę wpisać hasło',
  repeatPassword: 'Proszę powtórzyć swoje hasło',
        privacyPolicy: 'Polityka Prywatności',
                     login: 'Zaloguj się',
  logout: 'Wyloguj się',
       register: 'ZAREJESTRUJ',
      checkServiceTips:
    'Proszę najpierw zapoznać się z Warunkami użytkowania i Polityką prywatności.',
                     termsOfUse: 'Warunki użytkowania',
  copy_success: 'Skopiowano',
          creatework_goGame: 'Powrót',
        mywork_confirm: 'POTWIERDŹ',
                backLogin: 'ODMÓW',
                       storeFail: 'Nie udało się doładować',
  storeFailTips:
    'Transakcja nie powiodła się. Proszę złożyć zamówienie ponownie lub skontaktować się z naszym działem obsługi klienta.',
   storeSuccessTips: 'Transakcja zostanie zrealizowana w ciągu 15 minut.',
     failReason: 'Powód',
                forgetPwd: 'Zapomniałeś hasła?',
    goods: 'Przedmiot',
      pwdValidateMsg: 'Hasło musi zawierać od 6 do 20 znaków',
   fwtk: 'WARUNKI ŚWIADCZENIA USŁUG',
  yszc: 'POLITYKA PRYWATNOŚCI',
   remember: 'Pamiętaj o moim wyborze',
     favour: 'Zakładki',
  goStoreTip: 'Przejdź do strony płatności',
  go: 'Idź',
  otherLogin: 'Inne metody logowania',
  qrCodeTip: 'Zeskanuj kod QR, aby zacząć!',
  favourTip: 'Dodaj stronę do zakładek, aby później znów grać!',
  share: 'Udostępnij',
  landscapeTip: 'Proszę kontynuować w trybie portretowym',
  leaveTip: 'Wyjść z gry?',
     lowerIE: 'Za stara wersja IE',
  linkTermText:
    'Zaakceptowałem {mainHost} [Warunki użytkowania] i [Polityka prywatności]',
  download: 'Pobierz',
  favourAndDownloadTip: 'Dodaj tę stronę do wyszukiwarki lub pobierz na PC',
                                                                                      loginStateNone: 'Nie zalogowano się lub sesja wygasła.',
                                                                                                                                      registerTimeError: 'Niezgodna data rejestracji;',
    registerIPError: 'Niespójna data rejestracji;',
       h5_favour_has_gift:
    'Zakładka do przeglądarki! (To konto już odebrało nagrodę)',
  h5_favour_get_gift:
    'Gratulacje! Otrzymałeś nagrodę. Proszę sprawdzić w grze.',
  h5_get_gift_fault:
    'Nie można złożyć wniosku. Proszę spróbować ponownie później i skontaktować się z naszym Zespołem Obsługi, jeśli problem nadal występuje.',
  h5_favour_please_select: 'Proszę wybrać serwer/postać',
  h5_favour_login_get:
    'Zaloguj się i dodaj zakładkę do przeglądarki, aby odebrać nagrodę!',
  h5_favour_tip: 'Dodaj zakładkę do przeglądarki, aby odebrać nagrodę!',
  h5_favour_select_serve: 'Proszę wybrać serwer',
  h5_favour_select_role: 'Proszę wybrać postać',
     serviceTitle: 'Usługi',
  h5_download_login: 'Proszę się zalogować.',
  h5_download_tips: 'Proszę uruchomić grę z poziomu klienta.',
  h5_download_repeat_get: 'Zgłosiłeś ten pakiet upominkowy.',
  h5_download_succ: 'Proszę najpierw pobrać klienta.',
  h5_download_repeat_role: 'Postać jest powiązana',
   fb_canvas_fullscreen: 'Rozwiń',
  fb_canvas_website: 'Strona startowa',
  fb_canvas_like_share: 'Udostępnij',
  fb_canvas_service: 'Wsparcie',
  fb_canvas_login_fail: 'Nieudany login',
  fb_canvas_pay_return: 'Anulowałeś transakcję',
  fb_canvas_root_tip:
    'Proszę najpierw zaloguj się na Facebooku, aby dokonać autoryzacji',
  h5_popup_title: 'Płatność',
  h5_popup_submit: 'Zapłać',
  h5_popup_last_used: 'Ostatnio używane',
  h5_popup_quit_tips: 'Czy na pewno chcesz anulować płatność',
  h5_popup_quit_check: 'TAK',
  h5_popup_quit_cancel: 'NIE',
  h5_popup_currency_sign: '$',
  h5_popup_product_empty:
    'Informacje o produkcie nie zostały jeszcze uzyskane. Proszę odświeżyć stronę',
  h5_popup_server_empty:
    'Nie ma jeszcze listy serwerów. Proszę odświeżyć stronę',
  aboutRelevanceAccount: '',
  selectLoginType: '',
  touristDescription: '',
  touristSuggest: '',
  h5_tourist_remenber: '',
                          h5_header_gift: '',
  h5_award_popup_type: '',
  h5_award_popup_content: '',
  h5_award_popup_code: '',
  h5_favour_gift_name: '',
  h5_download_gift_name: '',
   h5_succ_get_gift: '',
  h5_exchange_info_title: '',
   h5_exchange_info_2: '',
  h5_check_gift: '',
         h5_guest_login: 'Gość',
  h5_click_copy: '',
   h5_header_store_text: 'Doładuj',
  h5_header_home_text: 'Strona startowa',
  h5_check_gift_list_text: 'Informacje o nagrodzie',
    h5_header_screen: 'Pełny Ekran',
  h5_verticalTip: 'Zalecany jest tryb krajobrazowy.',
  h5_third_party_login_tips: '',
  '-': '-',
  switch_horizontal: '',
  pwsInstall: 'Dodaj do ekranu startowego',
  voucherAddHome: 'Dodaj do pulpitu',
  voucherAddHomeTipOne: 'Szybsza płatność, lepsze doświadczenie',
  voucherAddHomeTipTwo: 'Stuknij [Udostępnij] w pasku narzędzi',
  voucherAddHomeTipThree: 'Wybierz [Dodaj do pulpitu]'
}
