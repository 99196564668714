export default {
  accountValidateMsg:
    'L\'account deve contenere da 6 a 30 caratteri, inclusi lettere, cifre e simboli (".", "_", "@"). Il primo carattere deve essere una cifra o una lettera.',
  storeSuccess: 'Ricarica Riuscita',
                       accountName: 'Nome account',
  password: 'Inserisci la tua password',
  repeatPassword: 'Per favore ripeti la tua password',
        privacyPolicy: 'Politica sulla Riservatezza',
                     login: 'Accedi',
  logout: 'Sconnetti',
       register: 'REGISTRATI',
      checkServiceTips:
    "Leggere prima le Condizioni d'uso e l'Informativa sulla privacy.",
                     termsOfUse: 'Condizioni d’uso',
  copy_success: 'Copiato',
          creatework_goGame: 'Ritorna',
        mywork_confirm: 'CONFERMA',
                backLogin: 'RIFIUTA',
                       storeFail: 'Impossibile ricaricare',
  storeFailTips:
    "Transazione fallita. Invia di nuovo l'ordine o contatta il nostro servizio clienti.",
   storeSuccessTips: 'La transazione deve essere effettuata entro 15 minuti.',
     failReason: 'Ragione',
                forgetPwd: 'Password dimenticata?',
    goods: 'Oggetto',
      pwdValidateMsg: 'La password deve contenere da 6 a 20 caratteri',
   fwtk: 'TERMINI DEL SERVIZIO',
  yszc: 'POLITICA SULLA RISERVATEZZA',
   remember: 'Ricorda la mia scelta',
     favour: 'Segnalibro',
  goStoreTip: 'Passa alla pagina di pagamento',
  go: 'Vai',
  otherLogin: 'Altri metodi di accesso',
  qrCodeTip: 'Scansiona il codice QR per iniziare!',
  favourTip: 'Aggiungi la pagina ai segnalibri per giocare di nuovo più tardi!',
  share: 'Condividi',
  landscapeTip: 'Procedere in modalità verticale',
  leaveTip: 'Exit the game?',
     lowerIE: 'Versione IE troppo vecchia',
  linkTermText:
    "Ho accettato {mainHost} [Termini d'uso] e [Informativa sulla privacy]",
  download: 'Scarica',
  favourAndDownloadTip: 'Aggiungi questa pagina ai browser o scaricala su PC',
                                                                                      loginStateNone: 'Non connesso o sessione scaduta.',
                                                                                                                                      registerTimeError: 'Data Registrazione inconsistente;',
    registerIPError: 'Indirizzo IP di Registrazione Inconsistente;',
       h5_favour_has_gift:
    'Aggiungi al browser! (Questo account ha già reclamato il premio)',
  h5_favour_get_gift:
    'Congratulazioni! Hai ottenuto la ricompensa. Si prega di controllare nel gioco.',
  h5_get_gift_fault:
    'Impossibile reclamare. Riprova più tardi e contatta il nostro CS se il problema persiste.',
  h5_favour_please_select: 'Per favore, scegli il personaggio',
  h5_favour_login_get:
    'Accedi e aggiungi ai segnalibri il browser per richiedere un premio!',
  h5_favour_tip: 'Aggiungi un segnalibro al browser per richiedere un premio!',
  h5_favour_select_serve: 'Scegli un server',
  h5_favour_select_role: 'Per favore, scegli il personaggio',
     serviceTitle: 'Servizio',
  h5_download_login: 'Per favore accedi prima.',
  h5_download_tips: 'Avvia il gioco dal cliente.',
  h5_download_repeat_get: 'Hai reclamato questo pacco regali.',
  h5_download_succ: 'Scarica prima il cliente.',
  h5_download_repeat_role: 'Personaggio associato',
   fb_canvas_fullscreen: 'Espandi',
  fb_canvas_website: 'Homepage',
  fb_canvas_like_share: 'Condividi',
  fb_canvas_service: 'Supporto',
  fb_canvas_login_fail: 'Accesso fallito',
  fb_canvas_pay_return: 'Hai annullato la transazione',
  fb_canvas_root_tip: 'Accedi a Facebook per autorizzare prima',
  h5_popup_title: 'Payment way',
  h5_popup_submit: 'Pay',
  h5_popup_last_used: 'Last used',
  h5_popup_quit_tips: 'Are you sure to cancel payment',
  h5_popup_quit_check: 'YES',
  h5_popup_quit_cancel: 'NO',
  h5_popup_currency_sign: '$',
  h5_popup_product_empty:
    'Product information has not been obtained yet. Please refresh page',
  h5_popup_server_empty: 'There is no server list yet. Please refresh page',
  aboutRelevanceAccount: '',
  selectLoginType: '',
  touristDescription: '',
  touristSuggest: '',
  h5_tourist_remenber: '',
                          h5_header_gift: '',
  h5_award_popup_type: '',
  h5_award_popup_content: '',
  h5_award_popup_code: '',
  h5_favour_gift_name: '',
  h5_download_gift_name: '',
   h5_succ_get_gift: '',
  h5_exchange_info_title: '',
   h5_exchange_info_2: '',
  h5_check_gift: '',
         h5_guest_login: 'Guest',
  h5_click_copy: '',
   h5_header_store_text: 'Recharge',
  h5_header_home_text: 'Homepage',
  h5_check_gift_list_text: 'Info ricompensa',
    h5_header_screen: 'Schermo intero',
  h5_verticalTip: 'Modalità orizzontale consigliata.',
  h5_third_party_login_tips: '',
  '-': '-',
  switch_horizontal: '',
  pwsInstall: 'Aggiungi alla schermata Home',
  voucherAddHome: 'Aggiungi al Desktop',
  voucherAddHomeTipOne: 'Pagamenti Più Veloci, Miglior Esperienza',
  voucherAddHomeTipTwo: 'Tocca [Condividi] nella barra degli strumenti',
  voucherAddHomeTipThree: 'Seleziona [Aggiungi al Desktop]'
}
